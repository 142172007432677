import { template as template_bbae1444ae984e1293e3ca3c7d50fe6b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_bbae1444ae984e1293e3ca3c7d50fe6b(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
