define("discourse/plugins/discourse-zoom/discourse/components/webinar-option-row", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-zoom/discourse/lib/webinar-helpers", "@ember/template-factory"], function (_exports, _component, _object, _decorators, _webinarHelpers, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="webinar-option">
    <a href class="webinar-topic" {{action "selectWebinar"}}>
      {{this.model.topic}}
    </a>
  
    <div class="webinar-schedule">
      {{this.schedule}}
    </div>
  
    <div class="webinar-id">
      ID:
      {{this.model.id}}
    </div>
  </div>
  */
  {
    "id": "HHcvQkRt",
    "block": "[[[10,0],[14,0,\"webinar-option\"],[12],[1,\"\\n  \"],[11,3],[24,6,\"\"],[24,0,\"webinar-topic\"],[4,[38,2],[[30,0],\"selectWebinar\"],null],[12],[1,\"\\n    \"],[1,[30,0,[\"model\",\"topic\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"webinar-schedule\"],[12],[1,\"\\n    \"],[1,[30,0,[\"schedule\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"webinar-id\"],[12],[1,\"\\n    ID:\\n    \"],[1,[30,0,[\"model\",\"id\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"a\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-zoom/discourse/components/webinar-option-row.hbs",
    "isStrictMode": false
  });
  class WebinarOptionRow extends _component.default {
    model = null;
    onSelect = null;
    init() {
      super.init(...arguments);
      this.onSelect = this.onSelect || (() => {});
    }
    schedule(model) {
      return (0, _webinarHelpers.formattedSchedule)(model.start_time, moment(model.start_time).add(model.duration, "m").toDate());
    }
    static #_ = (() => dt7948.n(this.prototype, "schedule", [(0, _decorators.default)("model")]))();
    selectWebinar() {
      this.onSelect();
    }
    static #_2 = (() => dt7948.n(this.prototype, "selectWebinar", [_object.action]))();
  }
  _exports.default = WebinarOptionRow;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WebinarOptionRow);
});