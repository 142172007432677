import { template as template_88e564d0637f4c6781856e09d10669ac } from "@ember/template-compiler";
const SidebarSectionMessage = template_88e564d0637f4c6781856e09d10669ac(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
