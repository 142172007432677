import { template as template_cbc9ecbad3f1450f8c800e0c7c1da3a5 } from "@ember/template-compiler";
import EmojiPickerContent from "discourse/components/emoji-picker/content";
const EmojiPickerDetached = template_cbc9ecbad3f1450f8c800e0c7c1da3a5(`
  <EmojiPickerContent
    @close={{@close}}
    @term={{@data.term}}
    @didSelectEmoji={{@data.didSelectEmoji}}
    @context={{@data.context}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmojiPickerDetached;
