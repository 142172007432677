import { template as template_cf65af8917404a17bcb323efc45ef38d } from "@ember/template-compiler";
const FKControlMenuContainer = template_cf65af8917404a17bcb323efc45ef38d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
