import { template as template_a7049af0e27f4ff6a7c26bb36e2a4612 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_a7049af0e27f4ff6a7c26bb36e2a4612(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
