import { template as template_7607d352974d4653907e9276df5e167a } from "@ember/template-compiler";
const FKLabel = template_7607d352974d4653907e9276df5e167a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
